.dropdown-menu {
  color: $dropdown-color;
  margin: $dropdown-margin-top 0 0;
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
  box-shadow: $dropdown-box-shadow;
  font-size: $dropdown-font-size;
  display: block;
  position: inherit;
  > li {
    border-radius: 0;
    &:first-child {
      border-top-left-radius: $dropdown-item-border-radius;
      border-top-right-radius: $dropdown-item-border-radius;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .dropdown-item {
        border-top-left-radius: $dropdown-item-border-radius;
        border-top-right-radius: $dropdown-item-border-radius;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:not(:first-child):not(:last-child) {
      .dropdown-item {
        border-radius: 0;
      }
    }

    &:last-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: $dropdown-item-border-radius;
      border-bottom-right-radius: $dropdown-item-border-radius;
      .dropdown-item {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: $dropdown-item-border-radius;
        border-bottom-right-radius: $dropdown-item-border-radius;
      }
    }
  }
}

.dropdown-item {
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  color: $dropdown-color;
  border-radius: 0;

  &:hover,
  &:focus {
    color: $dropdown-state-color;
    background-color: $dropdown-state-background-color;
  }

  &.active,
  &:active {
    color: $dropdown-state-color;
    background-color: $dropdown-state-background-color;
  }
}

.hidden-arrow {
  &.dropdown-toggle:after {
    display: none;
  }
}
