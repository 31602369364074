.form-control {
  &[type='file'] {
    &::-webkit-file-upload-button {
      background-color: transparent;
    }
  }
  &:hover {
    &:not(:disabled):not([readonly])::-webkit-file-upload-button {
      background-color: transparent;
    }
  }
}

.form-control-sm {
  &::-webkit-file-upload-button {
    padding: 0.28rem 0.5rem;
    margin: -0.28rem -0.5rem;
  }
}
