// Button variant
@mixin button-variant-mdb($background) {
  color: set-notification-text-color($background);
  background-color: $background;

  &:hover {
    color: set-notification-text-color($background);
    background-color: darken($background, 7.5%);
  }

  &:focus,
  &.focus {
    color: set-notification-text-color($background);
    background-color: darken($background, 7.5%);
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: set-notification-text-color($background);
    background-color: darken($background, 20%);

    &:focus {
      box-shadow: $btn-focus-box-shadow;
    }
  }

  &:disabled,
  &.disabled {
    color: set-notification-text-color($background);
    background-color: $background;
  }
}

// Button variant outline
@mixin button-outline-variant-mdb($color) {
  color: $color;
  border-color: $color;

  &:hover {
    color: $color;
    background-color: rgba(0, 0, 0, 0.02);
  }

  &:focus,
  &.focus {
    color: $color;
    background-color: transparent;
  }

  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: $color;
    background-color: transparent;

    &:focus {
      box-shadow: none;
    }
  }

  &:disabled,
  &.disabled {
    color: $color;
  }
}

// Button sizes
@mixin button-size-mdb(
  $padding-top,
  $padding-right,
  $padding-bottom,
  $padding-left,
  $font-size,
  $line-height
) {
  padding: $padding-top $padding-right $padding-bottom $padding-left;
  font-size: $font-size;
  line-height: $line-height;
}

// Button size variant outline
@mixin button-outline-size-mdb($padding-top, $padding-right, $padding-bottom, $padding-left) {
  padding: $padding-top $padding-right $padding-bottom $padding-left;
}
