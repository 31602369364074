// Popover

.popover {
  border: 0;
  box-shadow: $popover-box-shadow;
  position: unset;
  opacity: 0;
}

.popover-header {
  background-color: $popover-background-color;
}
