/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

body {}

.cursor-pointer {
  cursor: pointer;
}

@import '~mdb-angular-ui-kit/assets/scss/mdb.scss';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

.fixed-top {
  z-index: 1000 !important
}
.clearance-tape {
  background-size: 100% 10px !important;
  background-repeat: no-repeat !important;
  background: repeating-linear-gradient(
    -45deg,
  rgba(242, 163, 23, 1),
  rgba(242, 164, 23, 1) 15px,
  rgba(0,0,0, 1) 15px,
  rgba(0,0,0, 1) 30px
  );
}
