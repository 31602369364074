//
// Basic MDB table
//

.table {
  font-size: $table-font-size;

  > :not(caption) > * > * {
    padding: $table-cell-padding-y $table-cell-padding-x;
    transition: $table-hover-transition;
    background-image: none;
    background-color: var(--bs-table-accent-bg);
  }

  th {
    font-weight: 500;
  }

  tbody {
    font-weight: 300;
  }

  > :not(:last-child) > :last-child > * {
    border-bottom-color: $table-group-separator-color;
  }
}

.table-sm {
  > :not(caption) > * > * {
    padding: $table-cell-padding-y-sm $table-cell-padding-x-sm;
  }
}

@each $color, $value in $table-variants {
  @include table-variant-mdb($color, $value);
}
